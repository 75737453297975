<template>
  <v-container fluid ref="proj_cards" class="image-background">
    <app-bar :site_sections="site_sections" :contact="contact" positive :portfolio="portfolio"/>
    <v-row
      no-gutters
      justify="center"
    >
      <v-col cols="12" class="d-flex justify-center">
        <p class="display-1 secondary--text font-weight-bold mt-14">{{ $t('our_work.title') }}</p>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      justify="center"
      class="mt-2 mb-12"
    >
      <p class="secondary--text font-weight-medium">
        {{ $t('our_work.content') }}
      </p>
    </v-row>
    <v-row justify="center" class="px-md-14 px-5">
      <template v-for="(item, i) in items">
        <v-col :key="`project_${i}`" :cols="$vuetify.breakpoint.mobile ? 12 : 6" class="pa-5">
          <v-card
            :class="{ 'animatedproj--show': item.isIntersecting, 'animatedproj--hide': !item.isIntersecting }"
            class="animatedproj"
            :style="transform(i, item.isIntersecting)"
            :id="`${i}`"
            v-intersect="onIntersect"
            :color="setColor(i)"
            :height="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? '645' : '770'"
          >
            <v-row no-gutters :class="`${setSecondColor(i)}--text`">
              <v-col
                cols="12"
                class="position-absolute pa-4 pa-md-8 pb-0"
                :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'height: 160px;' : 'height: 210px;'"
              >
                <div class="d-inline-flex justify-space-between w-100">
                  <span>
                    <p v-if="item.country" class="font-weight-medium text-lg-h6 text-sm-body-1 text-subtitle-2">
                      {{ item.country }}
                    </p>
                  </span>
                  <span class="d-inline-flex">
                    <template v-if="item.type">
                      <p class="font-weight-light text-lg-h6 text-sm-body-1 text-subtitle-2 mr-2">{{ item.type }}</p>
                      <v-icon :color="setSecondColor(i)">
                        {{ item.type === 'Desarrollo' ? 'mdi-keyboard' : 'mdi-wrench' }}
                      </v-icon>
                    </template>
                  </span>
                </div>
                <p class="text-lg-h4 text-sm-h5 text-h6 font-weight-medium pt-lg-6 pt-4" >
                  {{ item.title }}
                </p>
              </v-col>
              <v-col
                cols="12"
                class="px-4 px-md-8 pt-lg-5 pt-0"
                :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'height: 130px;' : 'height: 205px;'"
              >
                <p class="font-weight-light text-sm-body-1 text-subtitle-2">
                  {{ item.text }}
                </p>
              </v-col>
              <v-col cols="12" class="px-4 px-lg-8 py-lg-4 py-2 d-inline-flex justify-space-between align-end" style="height: 75px;">
                <p class="font-weight-medium text-lg-h6 text-sm-body-1 text-subtitle-2">
                  {{ item.client }}
                </p>
                <p class="font-weight-light text-lg-h6 text-sm-body-1 text-subtitle-2">
                  {{ item.year }}
                </p>
              </v-col>
              <v-col cols="12" style="height: 300px;">
                <v-hover v-slot="{ hover }">
                  <v-img
                    :class="!$vuetify.breakpoint.mobile ? { 'on-hover': hover } : {}"
                    style="cursor: pointer;"
                    :src="item.imgSrc"
                    height="265"
                    class="mx-4 mb-4 white--text align-end"
                    :position="item.position ? item.position : '30% 55%'"
                    :gradient="hover || $vuetify.breakpoint.mobile ? `to top, rgba(10,10,10,1) 0%, ${$vuetify.breakpoint.mobile ? '14%' : '17%'}, rgba(10,10,10,0) 25%` : ''"
                    @click="clickImage(item)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <div v-if="hover || $vuetify.breakpoint.mobile" class="mb-4 mr-4">
                      <p
                        class="font-weight-medium text-right text-body-1"
                      >
                        Visitar
                        <v-icon color="white">mdi-link-variant</v-icon>
                      </p>
                    </div>
                  </v-img>
                </v-hover>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import AppBar from '../components/AppBar';

export default {
  name: 'Projects2',
  components: {
    AppBar,
  },
  data() {
    return {
      site_sections: [ // To be used in app bar
      ],
      portfolio: [
        {
          title: 'Ver otra propuesta',
          route: { name: 'Projects' },
        },
      ],
      items: [
        {
          imgSrc: 'https://trello-attachments.s3.amazonaws.com/5d97a25b7bdee48237db0f4d/608857de39d4a87b336c748a/0d752c91aab4b6466b14a35796b11581/image.png',
          title: 'Venew: Portal para arriendo de espacios por hora',
          text: 'Plataforma web para la oferta de espacios destinados a eventos, que pueden ser arrendados por hora.',
          client: 'Venew',
          position: '30% 0%',
          year: '2020 - 2021',
          type: 'Desarrollo',
          isIntersecting: false,
          URL: 'https://venew.co/',
        },
        {
          imgSrc: 'https://trello-attachments.s3.amazonaws.com/5d97a25b7bdee48237db0f4d/6088580b8f086c5944eadf04/1caabc30aba6a578f170ecb3cd0d05d8/image.png',
          title: 'Plataforma de Información escolar',
          country: '🇺🇸 EEUU',
          text: 'Desarrollo de una plataforma web informativa de las escuelas públicas de New Haven, Connecticut.',
          client: 'ConsiliumBots',
          year: '2020 - 2021',
          position: '30% 2%',
          type: 'Desarrollo',
          isIntersecting: false,
          URL: 'https://explore.newhavenmagnetschools.com/',
        },
        {
          imgSrc: 'https://trello-attachments.s3.amazonaws.com/5d97a25b7bdee48237db0f4d/6088580b8f086c5944eadf04/692239dbbd2b6529f9e072899f837e78/image.png',
          title: 'Plataforma de Información y postulación escolar',
          country: '🇪🇨 Ecuador',
          text: 'Desarrollo de una plataforma web informativa y para postulación a escuelas de la Provincia de Manabí, Ecuador.',
          client: 'ConsiliumBots',
          year: '2020 - 2021',
          position: '30% 0%',
          type: 'Desarrollo',
          isIntersecting: false,
          URL: 'https://inscripcionmanta.educacion.gob.ec/',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Plataforma de Información y postulación escolar',
          country: '🇧🇷 Brasil',
          text: 'Desarrollo de una plataforma web informativa y para postulación a las escuelas de Caruaru, Pernambuco.',
          client: 'ConsiliumBots',
          type: 'Desarrollo',
          isIntersecting: false,
          year: 2020,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Prototipo Plataforma de Postulacion',
          country: '🇵🇪 Perú',
          text: 'Desarrollo del prototipo de una plataforma web informativa y para postulación a las escuelas de Tacna, Perú.',
          client: 'ConsiliumBots',
          type: 'Desarrollo',
          isIntersecting: false,
          year: 2019,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Plataforma de Información pre-escolar',
          text: 'Desarrollo de un sistema de información pre-escolar para apoderados y directores de jardines infantiles.',
          client: 'ConsiliumBots',
          type: 'Desarrollo',
          isIntersecting: false,
          year: 2019,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Plataforma de Información y postulación escolar',
          country: '🇩🇴 Rep. Dominicana',
          text: 'Construcción de una plataforma web para explorar carreras universitarias de República Dominicana.',
          client: 'ConsiliumBots',
          year: '2019',
          type: 'Desarrollo',
          isIntersecting: false,
          URL: 'https://www.egresa.do/',
        },
        {
          imgSrc: 'https://trello-attachments.s3.amazonaws.com/5d97a25b7bdee48237db0f4d/608857dd2b89dd0f7098ff33/a7937e05822f26fe71f4f2191f9cc025/image.png',
          title: 'Plataforma de administración de campañas de moda',
          client: 'Seer',
          year: '2020 - 2021',
          position: '30% 0%',
          type: 'Desarrollo',
          isIntersecting: false,
          URL: 'https://www.helloseer.com/',
          text: 'Seer permite que vendedores de retail creen y envíen Lookbooks de moda personalizados a sus clientes a través de email y SMS.',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Mantención para despliegue de plataforma ECGO',
          text: 'Mantención y facilitación de despliegue multiplataforma de sistema de monitoreo de señales biomédicas.',
          client: 'SoluNova',
          type: 'Mantención',
          isIntersecting: false,
          year: 2020,
          URL: 'https://ecgo.cl/',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Mantención mural digital Vixonic',
          client: 'Vixonic',
          text: 'Mantención transversal plataforma Vixonic para gestión de aplicaciones, clientes, conexión con equipos e interacción con dispositivos SmartTV',
          type: 'Mantención',
          isIntersecting: false,
          year: '2019 - 2020',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Dashboard gDAS',
          text: 'Desarrollo de aplicación Android para interactuar por bluetooth con dispositivo gDAS24, para su configuración y obtención de data en tiempo real.',
          client: 'SouthernRock Geophysics',
          type: 'Desarrollo',
          isIntersecting: false,
          year: 2019,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Mantención plataforma Mañungo',
          text: 'Mantención de una plataforma de entrega de materias primas de alimentos para eventos.',
          client: 'Mañungo',
          type: 'Mantención',
          isIntersecting: false,
          year: 2018,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Robot Acepta',
          text: 'Desarrollo de bot que navega y recopila información determinada del sitio web del Servicio de Impuestos Internos.',
          client: 'Acepta',
          type: 'Desarrollo',
          isIntersecting: false,
          year: '2018 - 2019',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Rex+DO',
          text: 'Construcción de plataforma para realizar tareas ligadas a los servicios de DO de Mandomedio como lo son las encuestas de clima y las evaluaciones de desempeño.',
          client: 'Mandomedio',
          type: 'Desarrollo',
          isIntersecting: false,
          year: 2017,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Marketplace para bases de datos de machine learning',
          text: 'Plataforma web para generar y compartir bases de datos de aprendizaje computacional, etiquetando data en imágenes, compartiendo proyectos colaborativamente, y pudiendo vender bases de datos de etiquetas con otros usuarios de la plataforma.',
          client: 'Forcast',
          type: 'Desarrollo',
          isIntersecting: false,
          year: '2018 - 2019',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Simuladores de créditos hipotecarios',
          text: 'Construcción de simuladores de créditos hipotecarios para Banco Itaú y para Nuevo Capital.',
          client: 'Asicom',
          type: 'Desarrollo',
          isIntersecting: false,
          year: 2019,
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Workflow Itaú CAE',
          text: 'Construcción de la nueva plataforma para el banco Itaú que le permite gestionar las demandas para el cobro de los CAE.',
          client: 'Asicom',
          type: 'Desarrollo',
          isIntersecting: false,
          year: '2019 - 2020',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Workflow Hipotecario Nuevo Capital',
          text: 'Construcción del nuevo workflow para la gestión y toma de créditos hipotecarios de Nuevo Capital.',
          client: 'Asicom',
          type: 'Desarrollo',
          isIntersecting: false,
          year: '2019 - 2020',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Mantención de aplicación de pagos',
          text: 'Mantención de una aplicación móvil que permite a los usuarios acceder a precios preferenciales en locales adheridos y pagar mediante transferencia electrónica.',
          client: 'Nalkip',
          type: 'Mantención',
          isIntersecting: false,
          year: '2019',
        },
        {
          imgSrc: 'https://mir-s3-cdn-cf.behance.net/project_modules/1400_opt_1/c2a24572804813.5bf41d8429641.jpg',
          title: 'Centralized Choice and Assignment Systems',
          text: '?',
          client: '?',
          type: 'Desarrollo',
          isIntersecting: false,
          year: '?',
          URL: 'https://www.ccas-project.org/',
        },
      ],
    };
  },
  computed: {
    contact() {
      return {
        title: this.$t('app_bar.contact_us'),
        id: 'contact',
      };
    },
  },
  methods: {
    setColor(i) {
      if (this.$vuetify.breakpoint.mobile) {
        return i % 2 === 0 ? 'primary' : 'accent';
      }
      return (i % 4 === 0 || i % 4 === 3) ? 'accent' : 'primary';
    },
    setSecondColor(i) {
      if (this.$vuetify.breakpoint.mobile) {
        return i % 2 === 0 ? 'white' : 'primary';
      }
      return (i % 4 === 0 || i % 4 === 3) ? 'primary' : 'white';
    },
    onIntersect(entries) {
      this.items[entries[0].target.id].isIntersecting = entries[0].isIntersecting;
    },
    transform(i, visible) {
      let translate = 0;
      let scale = 1;
      if (!visible) {
        translate = i % 2 === 0 ? -10 : 10;
        scale = 1;
      }
      return `transform: translateX(${translate}%) scale(${scale})`;
    },
    clickImage(item) {
      window.open(item.URL || '/');
    },
  },
};
</script>

<style lang="scss" scoped>
.position-absolute {
  position: relative;
}
.image-background {
  background-color: #FEFEFE;
}
.animatedproj {
  transition: transform 0.8s ease-in-out, opacity 0.7s ease-in-out;
  &--show {
    opacity: 1;
  }
  &--hide {
    opacity: 0;
  }
}
</style>
